:root {
    --clr--blue-1: #153074;
    --clr--blue-2: #2045A1;
    --clr--blue-3: #3868D9;
    --clr--blue-4: #5886F2;
    --clr--blue-5: #D1DFFF;
    --clr--blue-6: #1434D6;
    --clr--grey-1: #565656;
    --clr--grey-2: #BFBFBF;
    --clr--grey-3: #D9D9D9;
    --clr--grey-4: #EBEBEB;
    --clr--grey-5: #F5F5F5;
    --clr--grey-6: #F9F9F9;
    --clr--grey-7: #F0F0F0;
    --clr--grey-8: #B7B7B7;
}

body {
    margin: 0;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--clr--grey-1);
    font-size: 16px;
}

html {
    font-size: 16px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.title-1 {
    font-weight: 700;
    font-size: 2.25rem;
}

.title-3 {
    font-weight: 700;
    font-size: 1.75rem;
}

.title-5 {
    font-weight: 700;
    font-size: 1.25rem;
}

.title-6 {
    font-weight: 700;
    font-size: 1rem;
}

.title-7 {
    font-weight: 700;
    font-size: 0.875rem;
}

.subtitle-4 {
    font-weight: 400;
    font-size: 1.5rem;
}

.subtitle-5 {
    font-weight: 400;
    font-size: 1.25rem;
}

.subtitle-6 {
    font-weight: 400;
    font-size: 1rem;
}

.subtitle-7 {
    font-weight: 400;
    font-size: 0.875rem;
}

.subtitle-8 {
    font-weight: 400;
    font-size: 0.75rem;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
}

button.ant-switch-small {
    min-width: 35px;
    height: 18px;
    line-height: 16px;
}

button.ant-switch-small .ant-switch-handle {
    width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
}

button.ant-switch-checked {
    background-color: var(--clr--blue-3) !important;
}

button.ant-switch {
    background-color: var(--clr--grey-1)
}

div.ant-table {
    font-size: 0.875rem;
    color: var(--clr--grey-1)
}

div.ant-table-thead>tr>th,
th.ant-table-cell,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
    padding: 12px 12px;
}

.ant-table-thead>tr>th {
    padding: 4px 12px;
}

div.ant-tabs-tab .ant-tabs-tab-btn:hover,
div.ant-tabs-tab .ant-tabs-tab-btn:active {
    color: var(--clr--blue-2)
}

div.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--clr--blue-2);
    font-weight: 700;
}

div.ant-tabs-tab {
    font-size: 1rem;
    padding: 6px 6px 10px 6px;
}

div.ant-tabs-ink-bar {
    height: 4px !important;
    position: absolute;
    background: var(--clr--blue-2);
    pointer-events: none;
}

.ant-input-number {
    width: 100%;
}

.ant-picker {
    width: 100%;
}

.ant-form-item-label>label {
    color: var(--clr--grey-1);
    font-size: 16px;
    line-height: 22px;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    font-size: 8px;
    content: "●";
    color: var(--clr--blue-4);
}

.ant-form-item-explain.ant-form-item-explain-error {
    text-align: left;
}

div.ant-modal-content {
    color: var(--clr--grey-1)
}

a u {
    color: var(--clr--blue-2)
}

div.upload-email-report-popover .popover-content {
    cursor: pointer;
    font-size: 1rem;
}

.popover-content {
    color: var(--clr--grey-1);
}

.popover-content .delete-icon {
    margin-right: 8px;
}

.ant-typography {
    color: var(--clr--grey-1);
}

input.ant-input,
textarea.ant-input,
span.ant-input-affix-wrapper,
div.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--clr--grey-1);
}